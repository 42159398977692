import React from "react";
import image1 from "../Assets/Images/marquee1.png";
import image2 from "../Assets/Images/marquee2.png";
import image3 from "../Assets/Images/marquee3.png";
import image4 from "../Assets/Images/big1.png";
import image5 from "../Assets/Images/marquee5.png";
import image6 from "../Assets/Images/big2.png";
import image7 from "../Assets/Images/big3.png";
import image8 from "../Assets/Images/marquee4.png";
import image9 from "../Assets/Images/marquee6.png";
import image10 from "../Assets/Images/marquee7.png";
import image11 from "../Assets/Images/marquee8.png";

const ImageMarquee = () => {
  return (
    <div class="Marquee">
      <div class="Marquee-content">
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image4} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image1} alt="" />
            <img src={image2} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image6} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image3} alt="" />
            <img src={image5} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image7} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image8} alt="" />
            <img src={image9} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image4} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image10} alt="" />
            <img src={image11} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image6} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image1} alt="" />
            <img src={image2} alt="" />
          </div>
        </div>
      </div>
      <div class="Marquee-content">
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image4} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image1} alt="" />
            <img src={image2} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image6} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image3} alt="" />
            <img src={image5} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image7} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image8} alt="" />
            <img src={image9} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image4} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image10} alt="" />
            <img src={image11} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image6} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image1} alt="" />
            <img src={image2} alt="" />
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default ImageMarquee;
