import React, { useState } from "react";
import SUPPORT_BACKGROUND from "../Assets/Images/supportBack.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../Config/AxiosInstance";
import APILIST from "../Config/ApiList";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PrivacyPreferencesModal from "./PrivacyPreferencesModal";

const RenderModal = ({ open, onClose, showChangePref, setOpenModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [savePrefrences, setSavePrefrences] = useState({})

  const handleClose = () => {
    setIsModalOpen(false);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Email address is required!")
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Please Provide Valid Email"
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("email", values.email);
      try {
        const res = await AxiosInstance.post(APILIST.SUBSCRIBE_POST, formData);
        if (res?.data?.s === 1) {
          toast.success(res?.data?.m);
          resetForm();
          onClose();
        } else if (res?.data?.s === 0) {
          toast.error(res?.data?.m);
        } else {
          console.log("error", "error");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
console.log(savePrefrences,"ff")
  return showChangePref === true ? (
    <PrivacyPreferencesModal
      open={isModalOpen}
      handleClose={handleClose}
      setOpenModal={setOpenModal}
      setSavePrefrences={setSavePrefrences}
    />
  ) : (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          event.stopPropagation();
        } else {
          onClose();
        }
      }}
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "20px",
          overflow: "scroll",
          "::-webkit-scrollbar": { width: "0px", height: "0px" },
        },
        "& .MuiDialogContent-root": {
          overflowY: "initial",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
      }}
    >
      <Box
        sx={{
          background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
          p: 2,
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column", lg: "column" },
          position: "relative",
        }}
      >
        {/* Close Icon Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "black",
          }}
        >
          <CloseIcon sx={{ fontSize: "38px" }} />
        </IconButton>

        <Box
          sx={{
            width: "100%",
            background: `url(${SUPPORT_BACKGROUND})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            p: { xs: 0.5, md: 5 },
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "26px",
                md: "40px",
                fontWeight: "500",
              },
            }}
          >
            Get running tips & tricks in your inbox every week.
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "16px",
                md: "22px",
                fontWeight: "400",
              },
            }}
          >
            You can unsubscribe at any time.
            <br /> We respect your privacy.
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "30px",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                name="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                placeholder={"Email Address"}
                sx={{
                  outline:
                    formik.touched.email && formik.errors.email
                      ? "1.5px solid red"
                      : "none",
                  width: { xs: "100%", md: "500px" },
                  mt: "10px",
                  "& .MuiOutlinedInput-notchedOutline ": {
                    outline: "none",
                    border: "none",
                  },
                  "& .MuiInputBase-root": {
                    border: "none",
                    background: "white",
                    fontSize: "18px",
                    borderRadius: "12px",
                  },
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "12px",
                }}
              />
              {formik.touched.email && formik.errors.email ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  {formik.errors.email}
                </Typography>
              ) : null}
              <Box
                sx={{
                  width: { xs: "100%", md: "500px" },
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    background: "#3B3055",
                    borderRadius: "12px",
                    p: 2,
                    mt: 2,
                    fontSize: "18px",
                    width: "100%",
                    "&:hover": { background: "#3B3055" },
                    textTransform: "none",
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RenderModal;
