export const APILIST = {
  FAQ_GET: "/api/content/get-faq",
  SUBSCRIBE_POST: "/api/content/subscribe-news-letter",
  GET_ALL_BLOGS: "/api/blog/get-all?count=0&offset=30",
  GET_BLOG_DETAILS:"/api/blog/get-details",
  GET_TERMS_AND_CONDITIONS: "/api/content/get-terms-condition",
  GET_PRIVACY_POLICY:"/api/content/get-privacy-policy",
  GET_CATEGORY_BLOGS : "/api/blog/get-category",
  GET_PARTNER: "/api/partner/get-partner",
  SEND_CONTACTUS_MESSAGE:"/api/contactus/send",
};

export default APILIST;
