import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const PrivacyPreferencesModal = ({
  open,
  handleClose,
  setOpenModal,
  setSavePrefrences,
}) => {
  const [marketingCookies, setMarketingCookies] = useState(false);
  const [personalizationCookies, setPersonalizationCookies] = useState(false);
  const [analyticsCookies, setAnalyticsCookies] = useState(false);

  const handleCheckboxChange = (setFunc) => (event) => {
    setFunc(event.target.checked);
  };

  const rejectAllCookies = () => {
    setMarketingCookies(false);
    setPersonalizationCookies(false);
    setAnalyticsCookies(false);
  };

  const allowAllCookies = () => {
    setMarketingCookies(true);
    setPersonalizationCookies(true);
    setAnalyticsCookies(true);
  };

  const savePreferences = () => {
    const preferences = {
      marketingCookies,
      personalizationCookies,
      analyticsCookies,
    };
    setSavePrefrences(preferences);
    handleClose();
    setOpenModal(true);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        // Prevent closing on backdrop click or escape key
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          event.preventDefault();
        }
      }}
      disableEscapeKeyDown
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "20px",
          overflow: "hidden",
          background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        },
        "& .MuiDialogContent-root": {
          overflowY: "initial",
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "40px",
            mt: 2,
          }}
        >
          Privacy Preferences
        </Typography>
      </DialogTitle>

      <DialogContent dividers sx={{ p: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            width: "100%",
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: "18px" }}>
            Essential cookies
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: "18px" }}>
            Required
          </Typography>
        </Box>
        <FormControlLabel
          label="Marketing cookies"
          control={
            <Checkbox
              checked={marketingCookies}
              onChange={handleCheckboxChange(setMarketingCookies)}
              sx={{
                color: "#3B3055",
                "&.Mui-checked": {
                  color: "#3B3055",
                },
              }}
            />
          }
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            width: "100%",
            mb: 1,
            ml: 0,
            fontSize: "16px",
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={personalizationCookies}
              onChange={handleCheckboxChange(setPersonalizationCookies)}
              sx={{
                color: "#3B3055",
                "&.Mui-checked": {
                  color: "#3B3055",
                },
              }}
            />
          }
          label="Personalization cookies"
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            width: "100%",
            mb: 1,
            ml: 0,
            fontSize: "16px",
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={analyticsCookies}
              onChange={handleCheckboxChange(setAnalyticsCookies)}
              sx={{
                color: "#3B3055",
                "&.Mui-checked": {
                  color: "#3B3055",
                },
              }}
            />
          }
          label="Analytics cookies"
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            width: "100%",
            ml: 0,
            fontSize: "16px",
          }}
        />
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          p: 2,
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          onClick={rejectAllCookies}
          sx={{
            width: "100%",
            backgroundColor: "#3B3055",
            color: "#fff",
            borderRadius: "12px",
            p: 1.5,
            fontSize: "18px",
            "&:hover": { backgroundColor: "#3B3055" },
          }}
        >
          Reject all cookies
        </Button>
        <Button
          variant="outlined"
          onClick={allowAllCookies}
          sx={{
            width: "100%",
            borderColor: "#fff",
            color: "#fff",
            borderRadius: "12px",
            p: 1.5,
            fontSize: "18px",
            "&:hover": { borderColor: "#fff" },
          }}
        >
          Allow all cookies
        </Button>
        <Button
          variant="contained"
          onClick={savePreferences}
          sx={{
            width: "100%",
            backgroundColor: "#FF725E",
            borderRadius: "12px",
            p: 1.5,
            fontSize: "18px",
            "&:hover": { backgroundColor: "#FF725E" },
          }}
        >
          Save preferences
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrivacyPreferencesModal;
