import { Box, Typography } from "@mui/material";
import React from "react";
import BACKGROUND from "../Assets/Images/whyusBack.png";
import MOBILE_BACKGROUND from "../Assets/Images/whyusmobileback.webp";
import ImageMarquee from "../Component/ImageMarquee";
const WhyChooseUs = () => {
  const whyUsList = [
    {
      title: "Customised Running Plans for You",
      subTitle:
        "Experience personalised running plans crafted to meet your fitness goals, whether it’s for health, endurance, or marathon training.",
    },
    {
      title: "Compatible with All Your Devices",
      subTitle:
        "Stay connected to your running goals from any device with seamless syncs with major platforms.",
    },
    {
      title: "Integrated Solutions",
      subTitle:
        "Our app includes a running performance tracker, wellbeing monitoring, and pace calculator to help you achieve your best.",
    },
    {
      title: "Flexibility and Strength for Running",
      subTitle:
        "Boost your strength and flexibility with customised running workouts designed to improve your running performance and help towards preventing injuries.",
    },
  ];

  return (
    <>
      <div
        id="whyus"
        style={{
          width: "100%",
          display: "flex",
          background: `url(${BACKGROUND})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          justifyContent: "center",
          alignItems: "center",
          padding: "80px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: 1, md: 3 },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: { xs: "18px", md: "28px" },
                  fontWeight: "500",
                  color: "#B190FF",
                }}
              >
                Why Choose
              </Typography>
              <Typography
                sx={{
                  color: "#EFE9FF",
                  fontSize: { xs: "28px", md: "38px", fontWeight: "500" },
                }}
              >
                Transformational Runner
              </Typography>
              <ul style={{ paddingLeft: "40px" }}>
                {whyUsList.map((item, index) => {
                  return (
                    <>
                      <li
                        className="whyuslist"
                        style={{
                          padding: "10px 0",
                          fontSize: "22px",
                          color: "white",
                        }}
                        key={index}
                      >
                        {item.title}
                      </li>
                      <Typography
                        sx={{
                          color: "#ffffff80",
                          fontSize: {
                            xs: "14px",
                            md: "18px",
                            fontWeight: "200",
                          },
                        }}
                      >
                        {item.subTitle}
                      </Typography>
                    </>
                  );
                })}
              </ul>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: { xs: 3, lg: 3 },
              px: { xs: 0, sm: 10, md: 20, lg: 3 },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={MOBILE_BACKGROUND} alt="" style={{ width: "80%" }} />
            </Box>
          </Box>
        </Box>
      </div>
      <Box sx={{ width: "100%" }}>
        <ImageMarquee />
      </Box>
    </>
  );
};

export default WhyChooseUs;
