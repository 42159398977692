import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AxiosInstance from "../Config/AxiosInstance";
import APILIST from "../Config/ApiList";

const BlogDetails = () => {
  const location = useLocation();
  const [blogDetail, setBlogDetail] = useState({});
  useEffect(() => {
    if (location?.state?.id) {
      getBlogDetails(location.state.id);
    }
  }, [location?.state?.id]);
  const getBlogDetails = async (id) => {
    const response = await AxiosInstance.get(APILIST.GET_BLOG_DETAILS, {
      params: {
        id: id,
      },
    });
    if (response && response?.data?.s) {
      console.log("re", response);

      setBlogDetail(response?.data?.r);
    }
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          background:
            "linear-gradient(180deg, #EFE9FF 0%, rgba(239, 233, 255, 0.1) 100%)",
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: "95%", lg: "80%", xl: "70%" },
            margin: "80px auto 50px auto",
            width: "100%",
            padding: "40px 0px 24px 0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gridGap: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "24px", sm: "30px", md: "38px" },
                fontWeight: "500",
                color: "rgb(36, 36, 36)",
                textAlign: "center",
                fontFamily: "Rubik",
              }}
            >
              {blogDetail?.title}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "24px", sm: "30px", md: "24px" },
                fontWeight: "500",
                color: "rgb(36, 36, 36)",
                textAlign: "center",
                fontFamily: "Rubik",
              }}
            >
              {blogDetail?.subtitle}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0px 10px 0px",
            }}
          >
            <img
              src={`https://devapi.transformationalrunners.com/${blogDetail?.cover_img}`}
              alt=""
              style={{ width: "100%", borderRadius: "25px" }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            {blogDetail?.blog_elements?.length > 0 && (
              <Typography
                dangerouslySetInnerHTML={{ __html: blogDetail?.[0]?.element }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BlogDetails;
