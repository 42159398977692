import React, { useEffect, useState } from "react";
import AxiosInstance from "../Config/AxiosInstance";
import APILIST from "../Config/ApiList";
import moment from "moment/moment";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ALL_BLOGS_HEAD from "../Assets/Images/AllblogsHead.png";

const AllBlogs = () => {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState("0");
  const handleButtonClick = (id) => {
    setSelectedIndex(id);
  };
  const [blogs, setBlogs] = useState([]);
  const [category, setCategory] = useState([]);
  const getBlogs = async () => {
    const response = await AxiosInstance.get(APILIST.GET_ALL_BLOGS, {
      params: selectedIndex !== "0" && { category_id: selectedIndex },
    });
    if (response?.data?.s === 1) {
      setBlogs(response?.data?.r);
    }
  };
  const getCategory = async () => {
    try {
      const res = await AxiosInstance.get(APILIST.GET_CATEGORY_BLOGS);
      console.log("res", res);

      if (res?.data?.s === 1) {
        const All = {
          id: "0",
          category_name: "All",
        };
        const resData = res?.data?.r;
        setCategory([All, ...resData]);
      }
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
    getCategory();
    getBlogs();
  }, [selectedIndex]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          pt: 10,
          pb: 5,
          minHeight: "100vh",
          background:
            "linear-gradient(180deg, #EFE9FF 0%, rgba(239, 233, 255, 0.1) 100%)",
        }}
      >
        <Box
          sx={{
            background: `url(${ALL_BLOGS_HEAD})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            p: { xs: 2, md: 15 },
            mb: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: "800px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "30px", lg: "46px" },
                fontWeight: "600",
                textAlign: "center",
                color: "white",
              }}
            >
              Our Blogs
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <Box
            sx={{
              width: "90%",
              display: "flex",
              justifyContent: "center",
              gap: "40px",
              flexWrap: "wrap",
              py: 5,
            }}
          >
            {category.map((item, index) => {
              return (
                <Button
                  key={index}
                  onClick={() => handleButtonClick(item.id)}
                  sx={{
                    borderRadius:"8px",
                    color: selectedIndex === item.id ? "white" : "black",
                 
                      border:selectedIndex === item.id ? "none" : "1px solid #0D0C1480",
                    background:
                      selectedIndex === item.id
                        ? "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)"
                        : "transparent",
                    textTransform: "none",
                    fontSize: "20px",
                    fontWeight: "400",
                  }}
                >
                  {item.category_name}
                </Button>
              );
            })}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              gap: "30px",
              px: 5,
              justifyContent: "center",
            }}
          >
            {blogs &&
              blogs.map((item, index) => {
                return (
                  <Card
                    key={index}
                    sx={{ width: "540px", borderRadius: "12px" }}
                  >
                    <CardMedia
                      component="img"
                      height="294"
                      image={`https://devapi.transformationalrunners.com/${item.cover_img}`}
                      alt="Paella dish"
                    />
                    <CardContent sx={{ background: "white" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <CalendarMonthIcon sx={{ color: "#7660AA" }} />
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            {moment(item.created_at).format("DD-MM-YYYY")}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "24px",
                            fontWeight: "500",
                            lineHeight: "25px",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Box
                          sx={{
                            maxHeight: "80px",
                            width: "100%",
                            overflow: "scroll",
                          }}
                        >
                          <Typography variant="body2" color="#606060">
                            {item.subtitle}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                    <CardActions disableSpacing sx={{ py: 0, pb: 2, px: 2 }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Link
                          style={{ color: "#B190FF", textDecoration: "none" }}
                        >
                          Learn more
                        </Link>
                        <IconButton
                          sx={{ background: "#D8C7FF" }}
                          onClick={() =>
                            navigate("/blogdetails", {
                              state: { id: item.id },
                            })
                          }
                        >
                          <KeyboardDoubleArrowRightIcon
                            sx={{ color: "black" }}
                          />
                        </IconButton>
                      </Box>
                    </CardActions>
                  </Card>
                );
              })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AllBlogs;
