import { Box, Typography } from "@mui/material";
import React from "react";
import BACKGROUND from "../Assets/Images/whyusBack.png";
import MOBILE_BACKGROUND from "../Assets/Images/whyusmobileback2.webp";
import SLIDER_BACKGROUND from "../Assets/Images/sliderback.png"

const BecomeaTransfometional = () => {
  const whyUsList = [
    {
      title: "Join a Global Running Community",
      subTitle:
        "Connect with like-minded runners around the world. Stay motivated, share your progress, and hold yourself accountable with the support of our vibrant, private community.",
    },
    {
      title: "Enjoy Exclusive Discounts & Offers",
      subTitle:
        "Unlock special deals on nutrition, apparel, supplements, and more. We’ve partnering with top brands to give our members exclusive savings on everything they need for a successful running journey.",
    },
    {
      title: "Participate in Events & Competitions",
      subTitle:
        "Participate in exciting events, share your passion and challenge yourself in friendly competitions.",
    },
    {
      title: "Access Professional Coaching & Support",
      subTitle:
        "Our expert running coach and dedicated support team are ready to assist you. Whether you need guidance or have questions about your training, simply reach out to us in-app.",
    },
    {
      title: "Track Your Progress & Achievements",
      subTitle:
        "Set goals, monitor your stats, and celebrate your milestones with personalised insights and progress-tracking tools — helping you stay on top of your fitness journey.",
    },
  ];
  return (
    <>
      <div
        id="whyus"
        style={{
          width: "100%",
          display: "flex",
          background: `url(${BACKGROUND})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          justifyContent: "center",
          alignItems: "center",
          padding: "80px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: { xs: 3, lg: 3 },
              px: { xs: 0, sm: 10, md: 20, lg: 3 },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={MOBILE_BACKGROUND} alt="" style={{ width: "80%" }} />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: 1, md: 3 },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: { xs: "18px", md: "28px" },
                  fontWeight: "500",
                  color: "#B190FF",
                }}
              >
                Become A
              </Typography>
              <Typography
                sx={{
                  color: "#EFE9FF",
                  fontSize: { xs: "28px", md: "38px", fontWeight: "500" },
                }}
              >
                Transformational Runner
              </Typography>
              <ul style={{ paddingLeft: "40px" }}>
                {whyUsList.map((item, index) => {
                  return (
                    <>
                      <li
                        className="whyuslist"
                        style={{
                          padding: "10px 0",
                          fontSize: "22px",
                          color: "white",
                        }}
                        key={index}
                      >
                        {item.title}
                      </li>
                      <Typography
                        sx={{
                          color: "#ffffff80",
                          fontSize: {
                            xs: "14px",
                            md: "18px",
                            fontWeight: "200",
                          },
                        }}
                      >
                        {item.subTitle}
                      </Typography>
                    </>
                  );
                })}
              </ul>
            </Box>
          </Box>
        </Box>
      </div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background:' linear-gradient(180deg, rgba(240, 235, 255, 0.1) 0%, #F0EBFF 100%)',
          pt:10
        }}
      >
        {/* <MobileViewSlider /> */}
        <img src={SLIDER_BACKGROUND} alt="" style={{width:"80%"}} />
      </Box>
    </>
  );
};

export default BecomeaTransfometional;
